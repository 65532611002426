import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  getCourseCohortCompletionsOnce,
  fetchUserData,
  getCourseCurrentCohort,
} from "api/db";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { Container, Verify } from "components/Courses/CertificateStyles";
import { render } from "react-dom";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Certificate = () => {
  const [data, setData] = useState(null); // userData
  const [cohort, setCohort] = useState(); // eventually add date data to this!
  const [milestones, setMilestones] = useState(null); // milestones & completion data
  const [cohortData, setCohortData] = useState([]); //date, cohort, name
  const courseName = "figma";
  const color = "purple";
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // let user = location.pathname.slice(27);
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    fetchUserData(user).once("value", (snapshot) => {
      let userData = Object.values(snapshot.val())[0];
      setData(userData);
      let uid = Object.keys(snapshot.val())[0];
      if (
        userData &&
        userData.courses &&
        userData.courses.find((s) => s.includes("f"))
      ) {
        let cohortNum = 0;
        let f = userData.courses.filter((s) => s.includes("f"));
        let fcurrent = f[f.length - 1];
        cohortNum = parseInt(fcurrent.slice(fcurrent.length - 1));
        getCourseCurrentCohort(courseName, cohortNum).then((snapshot) => {
          setCohortData(snapshot.val());
          setCohort(snapshot.val().cohort);
        });

        getCourseCohortCompletionsOnce(courseName, cohortNum, uid).then(
          (snapshot) => {
            let data = snapshot.val();
            data = Object.values(data).filter((a) => a.milestone == "demo-day");
            setMilestones(data);
          }
        );
        setLoading(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      ) : (
        <>
          {milestones ? (
            <Container>
              <div className="flex flex-col content-between">
                <div className="flex-shrink-0 h-2/5">
                  <h3 className="text-xl mb-2 tracking-tight">
                    {cohortData ? cohortData.date : "no date"}
                  </h3>
                  <div
                    className={`py-1 bg-${color}-600 hover:bg-${color}-500 items-center text-${color}-100 leading-none rounded-full flex inline-flex mb-2 cursor-default`}
                    role="alert"
                  >
                    <span
                      className={`flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2 print:ml-0 print:px-0 print:text-black`}
                    >
                      {cohortData.name} {cohortData.cohort}
                    </span>
                  </div>
                  <h1 className="tracking-widest uppercase font-bold text-xl mt-4">
                    THIS CERTIFICATE VERIFIES THAT
                  </h1>
                  <h1 className="tracking-tight text-4xl font-bold my-2">
                    {data && data.username}
                  </h1>
                  <p className="text-xl max-w-lg">
                    {`has built a prototype in Figma through Enlight's two-week cohort on UI/UX, wireframing, and prototyping.`}
                  </p>
                  <h1 className="tracking-widest uppercase font-bold text-base mt-4">
                    Prototype
                  </h1>
                </div>
                <div className="flex-shrink-0 mb-2 max-w-lg h-3/5 text-gray-900 my-2 text-left">
                  <h2 className="tracking-tight text-xl font-bold md:mb-6 mb-2">
                    {milestones[0].prototypeName}
                  </h2>
                  {milestones[0].img ? (
                    <>
                      <img src={milestones[0].img} className="h-64 max-w-md" />
                    </>
                  ) : null}
                  <p className="text-base mt-2">
                    {milestones[0].description &&
                      `${milestones[0].description}`}
                  </p>
                  {milestones[0].demo ? (
                    <>
                      <div className="md:my-6 my-2">
                        <a
                          target="_blank"
                          className="text-base tracking-tight mb-4 p-2 print:pl-0 rounded-lg bg-purple-600 text-purple-100 print:text-black hover:opacity-75 cursor-pointer"
                          href={milestones[0].demo}
                        >
                          View on Figma &#8250;
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <img src="/img/ribbon.png" id="ribbon" />

              <Verify>
                Verify at https://enlight.nyc/courses/figma/certificate?user=
                {data && data.id} <br />
                Enlight has confirmed their participation in this course.
              </Verify>
            </Container>
          ) : null}
        </>
      )}
    </>
  );
};

export default Certificate;
