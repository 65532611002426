import styled from "styled-components";

export const Container = styled.div`
  padding: 32px;
  height: 100vh;
  background: #fafafa;
  box-sizing: border-box;

  @media print {
    margin: 0;
    @page {
      size: 11in 8.5in;
      size: landscape;
    }
  }

  .cohortDescription {
    margin-bottom: 20px;
    max-width: 500px;
    font-weight: 500;
  }

  h1,
  h2 {
    color: #333;
  }

  span {
    letter-spacing: 1px;
    h3 {
      margin-bottom: 20px;
    }
    font-weight: 600;
  }

  p {
    color: #474747;
  }

  /* section {
  display: flex;
  flex-direction: row;
  div {
    display: flex;
    word-wrap: break-word;
  }

  .column {
    flex-direction: column;
  }
  img {
    width: auto;
    height: 200px;
  }
} */

  .row {
    /* div {
      justify-content: center;
    } */

    div {
      margin: 10px;
      max-width: 500px;
      min-width: 300px;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    div p a {
      display: inline;
    }

    div h3 {
      margin-bottom: 10px;
      color: #333;
    }

    max-width: 1000px;
    @media print {
      max-width: 700px;
    }
    div img {
      display: block;
      margin: 0 auto;
    }
    margin: 10px;
  }

  #ribbon {
    position: absolute;
    right: 80px;
    top: 0px;
    height: 650px;
    @media (max-width: 1024px) {
      display: none;
    }
    @media print {
      display: block;
    }
  }

  .button {
    display: inline-block;
    margin-top: 10px;

    @media print {
      display: none;
    }
  }

  .link {
    display: none;
    @media print {
      display: block;
    }
  }
`;

export const IBuiltThisButton = styled.a`
  /* height: 100px;
  width: 50%; */
  display: inline;
  padding: 8px;
  border: 2px solid ${props => props.bg};
  margin-right: 8px;
  color: #333;
  border-radius: 4px;
  transition: all 300ms ease;
  font-weight: bold;

  &:hover {
    color: #fff;
    background: ${props => props.bg};
  }

  @media only screen and (max-width: 1024px) {
    display: block;
    width: 90%;
    height: 20px;
    margin: 8px;
  }
`;

export const Verify = styled.p`
  bottom: 24px;
  right: 24px;
  position: absolute;
  text-align: right;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (max-height: 767px) {
    display: none;
  }
`;
